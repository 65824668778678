<template>
  <div class="article" :class="{ active: fullScreen }">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <div class="title_option">
          <h5 class="title">시간당 생산량(P)</h5>
          <button class="btn_sub1" @click="fullScreen = !fullScreen">
            {{ fullScreen ? '닫기' : '전체화면' }}
          </button>
        </div>
        <div class="select_option input_text">
          <span>실적년도</span>
          <datePicker
            type="year"
            minimum-view="year"
            :value="PTargetYear"
            @selected="changeTargetYear($event)"
            :format="DatePickerFormat"
          />
        </div>
      </div>
      <swiper
        ref="mySwiperRef"
        class="swiper-container"
        :options="swiperOption"
      >
        <swiper-slide class=" swiper-slide">
          <div class="mes_tbl_wrap">
            <table class="mes_tbl">
              <thead>
                <tr>
                  <th>No</th>
                  <th>분야</th>
                  <th>핵심지표(KPI)</th>
                  <th>단위</th>
                  <th>측정대상</th>
                  <th>기존</th>
                  <th>목표</th>
                  <th>실적</th>
                  <th>비고</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in filteredKPI" :key="item.id">
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.kpi_kind }}</td>
                  <td>{{ item.kpi_name }}</td>
                  <td>{{ item.unit_cd }}</td>
                  <td>{{ findInfoFromId(products, item.product_id).name }}</td>
                  <td>
                    {{ $makeComma(item.crt_val)
                    }}<b>{{ ` ${item.unit_cd}` }}</b>
                  </td>
                  <td>
                    {{ $makeComma(item.tgt_val)
                    }}<b>{{ ` ${item.unit_cd}` }}</b>
                  </td>
                  <td>
                    {{ $makeComma(item.quantity.quantity)
                    }}<b>{{ ` ${item.unit_cd}` }}</b>
                  </td>
                  <td class="text_left">
                    {{ '⇒ [실적 :'
                    }}<b>{{
                      ` ${$makeComma(item.quantity.quantity)} ${item.unit_cd}`
                    }}</b>
                    {{ '] = ([월 생산량 합 : '
                    }}<b>{{
                      $makeComma(item.quantity.monthly_total_quantity)
                    }}</b
                    >{{ '] / [평균 근무일수 : ' }}<b>{{ 22 }}</b
                    >{{ ' 일]) /[평균 근무시간 : ' }}<b>{{ 9 }}</b
                    >{{ ' 시간]' }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </swiper-slide>
        <swiper-slide class="swiper-slide chart-slide">
          <line-chart
            :data="chartData.data"
            :options="chartData.options"
            :key="key"
          ></line-chart>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import datePicker from 'vuejs-datepicker';
import LineChart from '@/layouts/components/charts/LineChart';
import SWIPER_MIXIN from '@/mixins/status_board_swiper';
import FetchMixin from '@/mixins/fetch';

export default {
  mixins: [FetchMixin, SWIPER_MIXIN],
  components: {
    LineChart,
    datePicker,
  },
  data() {
    return {
      fullScreen: false,
      DatePickerFormat: 'yyyy',
      key: 0,
    };
  },
  computed: {
    ...mapGetters({
      kpis: 'getKpiTarget',
      products: 'getVisibleProduct',
      PTargetYear: 'getPTargerYearFromKpiTarget',
      perHour: 'getKpiProductionPerHour',
    }),
    filteredKPI() {
      const QList = this.kpis
        .filter(x => x.kpi_kind == 'P')
        .map(x => ({
          ...x,
          quantity:
            this.countByQuantity.find(y => y.product_id == x.product_id) !=
            undefined
              ? this.countByQuantity.find(y => y.product_id == x.product_id)
              : {
                  date_avg: 0,
                  monthly_total_quantity: 0,
                  quantity: 0,
                },
        }));

      return QList;
    },
    WeekendExceptDate() {
      let startDate = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1,
      );
      let endDate = new Date();

      let totalWeekendExceptDate = 0;

      while (startDate < endDate) {
        let temp_date = startDate;
        if (temp_date.getTime() > endDate.getTime()) {
          console.log(totalWeekendExceptDate);
          break;
        } else {
          let tmp = temp_date.getDay();
          if (tmp != 0 && tmp != 6) {
            totalWeekendExceptDate++;
          }
          temp_date.setDate(startDate.getDate() + 1);
        }
      }
      return totalWeekendExceptDate;
    },
    countByQuantity() {
      if (this.perHour != undefined && this.perHour.length > 0) {
        const per_hour = this.lodash.clonedeep(this.perHour);
        let filter_input_date = per_hour.map(x => ({
          product_id: x.product_id,
          total_quantity: Math.max.apply(
            null,
            x.lot_process.map(x => x.total_quantity),
          ),
        }));
        // console.log('array', per_hour);

        const obj = filter_input_date.reduce(function(rv, x) {
          (rv[x['product_id']] = rv[x['product_id']] || []).push(x);
          return rv;
        }, {});
        let arr = [];
        for (let k in obj) {
          const totalQuantity = obj[k]
            .map(x => x.total_quantity)
            .reduce((a, b) => a + b, 0);
          let currMonth = new Date().getMonth();
          let dateAvg = 0;
          if (currMonth != 0) {
            dateAvg = (currMonth * 20 + this.WeekendExceptDate) / currMonth + 1;
          } else {
            dateAvg = this.WeekendExceptDate;
          }
          arr.push({
            product_id: Number(k),
            monthly_total_quantity: totalQuantity,
            date_avg: dateAvg,
            quantity: Math.round(
              this.$decimalDiv(this.$decimalDiv(totalQuantity, 22), 9),
            ),
          });
        }

        return arr;
      } else {
        return [];
      }
    },
    countByTime() {
      if (this.perHour != undefined && this.perHour.length > 0) {
        const filter_kpi = this.lodash.clonedeep(this.filteredKPI);
        const per_hour = this.lodash.clonedeep(this.perHour);

        let filter_arr = [];
        per_hour.forEach(el => {
          filter_kpi.forEach(el2 => {
            el2.product_id == el.product_id ? filter_arr.push(el) : '';
          });
        });
        let filter_input_date = filter_arr.map(x => ({
          create_month: x.create_time.substring(5, 7),
          quantity: Math.round(
            // this.$decimalDiv(
            //   this.$decimalDiv(
            Math.max.apply(
              null,
              x.lot_process.map(x => x.pass_quantity),
            ),
            //     20,
            //   ),
            //   9,
            // ),
            // new Date(x.create_time.substring(6, 7)) == new Date().getMonth()
            //       ? this.WeekendExceptDate
            //       : (x.create_time.substring(6, 7) * 20 +
            //           this.WeekendExceptDate) /
            //           x.create_time.substring(6, 7) +
            //           1,
          ),
        }));

        const obj = filter_input_date.reduce(function(rv, x) {
          (rv[x['create_month']] = rv[x['create_month']] || []).push(x);
          return rv;
        }, {});
        let arr = [];
        for (let k in obj) {
          arr.push({
            month: k,
            quantity: Math.max.apply(
              null,
              obj[k].map(x => x.quantity),
            ),
          });
        }
        // console.log('arr', arr);
        return arr;
      } else {
        return [];
      }
    },
    chartData() {
      let month = new Date();
      month.setMonth(month.getMonth() - 4);
      let year = month.getFullYear();
      let labels = [];
      let thisMonth = month.getMonth() + 1;
      for (let i = 0; i < 7; i++) {
        if (thisMonth == 13) {
          thisMonth = 1;
          year += 1;
        } else if (thisMonth == -1) {
          thisMonth = 12;
          year -= 1;
        }
        if (i == 6) {
          year = '';
          thisMonth = '';
        }
        labels.push({
          this_month:
            thisMonth > 9
              ? `${year}-${thisMonth}`.substr(2, 6)
              : `${year}-0${thisMonth}`.substr(2, 6),
          month_index: thisMonth > 9 ? `${thisMonth}` : `0${thisMonth}`,
          month_counter: 0,
        });
        thisMonth++;
      }
      labels.forEach(el => {
        const elementTemp = this.countByTime.find(
          x => el.month_index == x.month,
        );
        if (elementTemp != undefined) {
          el.month_counter = Math.round(elementTemp.quantity);
        }
        // console.log(labels);
      });
      let monthSum = [];
      let labelsMap = labels.map(x => x.month_counter);
      labelsMap.reduce((acc, cur, index) => {
        return (monthSum[index] = this.$decimalAdd(acc, cur));
      }, 0);
      console.log(labels);
      const data = {
        labels: [
          '1월',
          '2월',
          '3월',
          '4월',
          '5월',
          '6월',
          '7월',
          '8월',
          '9월',
          '10월',
          '11월',
          '12월',
        ],
        datasets: [
          {
            label: '월별 실적',
            data: labelsMap.slice(0, labelsMap.length - 1),
            borderColor: '#272f68',
            backgroundColor: '#272f68',
            fill: false,
            borderWidth: 2,
            bezierCurve: false,
          },
        ],
      };
      let options = {
        legend: {
          display: true,
          labels: {
            boxWidth: 12,
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                callback: value => {
                  return this.$makeComma(value);
                },
              },
            },
          ],
        },
        elements: {
          line: {
            tension: 0.1,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: 78,
        hover: {
          mode: 'nearest',
          intersect: true,
        },
        tooltips: {
          mode: 'index',
          intersect: false,
          custom: function(tooltip) {
            if (!tooltip) return;
            tooltip.displayColors = false;
          },
          enabled: false,
        },
      };
      return { data: data, options: options };
    },
  },
  methods: {
    async changeTargetYear(e) {
      this.$store.commit('setPTargerYearToKpiTarget', String(e.getFullYear()));

      this.showSpinner();

      await this.FETCH_WITH_PAYLOAD_NO_SPINNER(
        'FETCH_KPI_TARGET',
        this.PTargetYear,
      );
      await this.FETCH_WITH_PAYLOAD_NO_SPINNER(
        'FETCH_LOT_PRODUCTION_YEAR',
        this.PTargetYear,
      );

      this.hideSpinner();
    },
    async FETCH_SALES() {
      this.showSpinner();
      await this.$store
        .dispatch('FETCH_SALES', {
          start_date: this.start_date,
          end_date: this.end_date,
          check_only_not_completed: false,
        })
        .then(() => {})
        .catch(error => {
          console.log(error);
          this.openOneButtonModal(
            '로드 중 오류',
            '수주 리스트를 불러오는 중 오류발생',
          );
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
  },
  async created() {
    this.showSpinner();

    let year = String(new Date().getFullYear());
    if (this.PTargetYear == null) {
      this.$store.commit('setPTargerYearToKpiTarget', year);
    }
    if (this.products.length == 0) {
      await this.FETCH_NO_SPINNER('FETCH_PRODUCT', '제품');
    }

    await this.FETCH_WITH_PAYLOAD_NO_SPINNER(
      'FETCH_KPI_PRODUCTION_PER_HOUR',
      year,
      '시간당 생산량',
    );

    await this.FETCH_WITH_PAYLOAD_NO_SPINNER(
      'FETCH_LOT_PRODUCTION_YEAR',
      this.PTargetYear,
    );

    if (this.kpis.length < 1) {
      await this.FETCH_WITH_PAYLOAD_NO_SPINNER(
        'FETCH_KPI_TARGET',
        this.PTargetYear,
      );
    }
    this.key++;
    this.hideSpinner();
  },
};
</script>

<style></style>
