<template>
  <div class="article">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <div class="title_option">
          <h5 class="title">KPI 목표 설정</h5>
        </div>
        <div class="select_option">
          <span>실적년도</span>
          <div class="input_text">
            <datePicker
              type="year"
              minimum-view="year"
              :value="selectDate"
              :format="DatePickerFormat"
              @selected="changeTargetYear($event)"
            />
          </div>
        </div>
      </div>
      <div class="mes_tbl_wrap">
        <table class="mes_tbl">
          <thead>
            <tr>
              <th>No</th>
              <th>분야</th>
              <th>핵심지표(KPI)</th>
              <th>단위</th>
              <th>측정대상</th>
              <th>현재</th>
              <th>목표</th>
              <th>가중치</th>
              <th>비고</th>
              <th v-show="managementMode">삭제</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(kpi, index) in filtered_kpi"
              :key="kpi.id"
              @click="SelectRow(index)"
              :class="selectedIndex == index ? 'active' : ''"
            >
              <td>{{ index + 1 }}</td>
              <td>{{ kpi.kpi_kind }}</td>
              <td>{{ kpi.kpi_name }}</td>
              <td>{{ kpi.unit_cd }}</td>
              <td>{{ findInfoFromId(products, kpi.product_id).name }}</td>
              <td>
                {{ kpi.crt_val }}<b>{{ ` ${kpi.unit_cd}` }}</b>
              </td>
              <td>
                {{ kpi.tgt_val }}<b>{{ ` ${kpi.unit_cd}` }}</b>
              </td>
              <td>
                {{ kpi.wgt_val }}
              </td>
              <td>{{ kpi.remark }}</td>
              <td v-show="managementMode">
                <button class="tbl_delete_btn" @click="ShowModal(index)">
                  delete
                </button>
              </td>
            </tr>

            <tr
              class="new newBtn"
              v-show="managementMode"
              :class="selectedIndex == -1 ? 'new' : 'new_disable'"
            >
              <td colspan="10" @click="SelectRow(-1)">
                + 신규 KPI 목표 등록
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="aside_management_mode">
      <div class="head">
        <h5>
          {{ selectedIndex != -1 ? '내용 수정' : '신규 등록' }}
        </h5>
      </div>
      <div class="form" @submit.prevent>
        <form>
          <div class="input_text">
            <label>분야</label>
            <my-selectric
              :id="'kpifield_selectric'"
              :options="kpiFieldOption"
              :watch="managementData.kpi_kind_select_option"
              :commit="'setManagementDataToKpiTargetKind'"
              :state="!managementMode ? 'disabled' : ''"
            >
            </my-selectric>
          </div>
          <div class="input_text">
            <label>핵심지표(KPI)</label>
            <input
              type="text"
              placeholder=""
              readonly
              :value="managementData.kpi_name"
            />
          </div>
          <div class="input_text">
            <label>단위</label>
            <input
              type="text"
              placeholder="단위 입력"
              :value="managementData.unit_cd"
              readonly
            />
          </div>
          <div class="input_text">
            <label>측정대상</label>
            <div class="input_search">
              <input
                type="text"
                placeholder="측정대상 검색"
                :disabled="!managementMode"
                :value="
                  managementData.product_id != null
                    ? getProductName(managementData.product_id)
                    : product_search_text
                "
                readonly
              />
              <button>
                <i
                  class="fa fa-search"
                  @click="showProductSearch = true"
                  v-if="managementMode"
                ></i>
              </button>
            </div>
          </div>
          <div class="input_text">
            <label>현재</label>
            <input
              type="text"
              inputmode="decimal"
              placeholder="현재값 입력"
              :value="$makeComma(managementData.crt_val)"
              @input="$inputNumber($event, managementData, 'crt_val')"
              :disabled="!managementMode"
            />
          </div>
          <div class="input_text">
            <label>목표</label>
            <input
              type="text"
              inputmode="decimal"
              placeholder="목표값 입력"
              :value="$makeComma(managementData.tgt_val)"
              @input="$inputNumber($event, managementData, 'tgt_val')"
              :disabled="!managementMode"
            />
          </div>
          <div class="input_text">
            <label>가중치</label>
            <input
              type="text"
              inputmode="decimal"
              placeholder="가중치값 입력"
              :value="$makeComma(managementData.wgt_val)"
              @input="$inputNumber($event, managementData, 'wgt_val')"
              :disabled="!managementMode"
            />
          </div>

          <div class="input_text">
            <label>비고</label>
            <textarea
              placeholder="비고 입력"
              :value="managementData.remark"
              @input="typing($event)"
              :disabled="!managementMode"
            />
          </div>
          <div class="btn_wrap">
            <button
              class="btn_sub2"
              v-show="managementMode"
              @click="submitForm()"
              :disabled="isValidModify || !checkValid"
            >
              {{ selectedIndex != -1 ? '수정' : '등록' }}
            </button>
          </div>
        </form>
      </div>
    </div>
    <two-button-modal
      :modal_title="my_modal_title"
      :modal_content="my_modal_content"
      :isModalOpen="my_isModalOpen"
      :index="my_modal_index"
      @onclose="CloseModal"
      @OnYesClick="deleteKpiTarget($event)"
    ></two-button-modal>
    <resource-search
      v-if="showProductSearch"
      :filter_type="2"
      :pre_search_value="product_search_text"
      @onclose="showProductSearch = false"
      @onselect="selectProduct($event)"
    ></resource-search>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MySelectric from '@/layouts/components/MySelectric.vue';
import datePicker from 'vuejs-datepicker';
import ModalMixin from '@/mixins/modal';
import SpinnerMixin from '@/mixins/spinner';
import FavoriteMixin from '@/mixins/favorite';
import TwoButtonModal from '@/layouts/components/TwoButtonModal';
import FetchMixin from '@/mixins/fetch';
import ResourceSearch from '@/layouts/components/search-popup/ResourceSearch';
export default {
  mixins: [ModalMixin, SpinnerMixin, FavoriteMixin, FetchMixin],
  components: {
    TwoButtonModal,
    MySelectric,
    datePicker,
    ResourceSearch,
  },
  data() {
    return {
      //측정대상
      showProductSearch: false,
      product_search_text: '',
      //modal
      my_modal_title: '',
      my_modal_content: '',
      my_modal_index: -1,
      my_isModalOpen: false,

      fullScreen: false,
      DatePickerFormat: 'yyyy',
    };
  },
  computed: {
    ...mapGetters({
      managementMode: 'getManagementModeFromKPIDefectiveRatePage',
      managementData: 'getManagementDataFromKpiTarget',
      selectDate: 'getSelectYearFromKpiTarget',
      kpis: 'getKpiTarget',
      selectedIndex: 'getSelectedIndexFromKpiTarget',
      products: 'getProduct',
      kpiFieldOption: 'getKPITteokOptions',
    }),
    filtered_kpi() {
      return this.lodash.clonedeep(this.kpis).sort((a, b) => a.id - b.id);
    },
    checkValid() {
      if (
        this.managementData.kpi_kind_select_option == -1 ||
        this.managementData.product_id == null ||
        this.managementData.crt_val == 0 ||
        this.managementData.tgt_val == 0 ||
        this.managementData.wgt_val == 0
      ) {
        return false;
      } else return true;
    },
    isValidModify() {
      if (this.managementMode && this.managementData.id != -1) {
        let modifyData = this.lodash.clonedeep(this.managementData);
        console.log('modifyData : ', modifyData);
        delete modifyData.kpi_kind_select_option;
        modifyData.crt_val = this.$makeNumber(modifyData.crt_val);
        modifyData.tgt_val = this.$makeNumber(modifyData.tgt_val);
        modifyData.wgt_val = this.$makeNumber(modifyData.wgt_val);
        modifyData.remark = modifyData.remark.trim();
        modifyData = JSON.stringify(modifyData);
        const originData = JSON.stringify(
          this.filtered_kpi[this.selectedIndex],
        );
        if (modifyData == originData) {
          return true;
        } else return false;
      } else return false;
    },
    vaildProduct() {
      const productChk = this.lodash
        .clonedeep(this.kpis)
        .find(
          x =>
            x.id != this.managementData.id &&
            x.kpi_kind == this.managementData.kpi_kind &&
            x.product_id == this.managementData.product_id,
        );
      if (productChk != undefined) {
        return true;
      } else return false;
    },
  },
  methods: {
    ShowModal(index) {
      this.my_modal_index = index;
      this.my_modal_title = '경고';
      this.my_modal_content = '정말로 삭제하시겠습니까?';
      this.my_isModalOpen = true;
    },
    CloseModal() {
      this.my_modal_index = -1;
      this.my_modal_title = '';
      this.my_modal_content = '';
      this.my_isModalOpen = false;
    },
    SelectRow(index) {
      this.$store.commit('setSelectedIndexToKpiTarget', index);
      if (index == -1) {
        this.$store.commit('setManagementDataToKpiTarget', {
          id: -1,
          kpi_year: this.selectDate,
          kpi_no: -1,
          kpi_kind_select_option: -1,
          kpi_name: '',
          kpi_kind: '',
          unit_cd: null,
          product_id: null,
          crt_val: 0,
          tgt_val: 0,
          wgt_val: 0,
          remark: '',
        });
      } else {
        this.$store.commit(
          'setManagementDataToKpiTarget',
          this.lodash.clonedeep(this.filtered_kpi[index]),
        );
        this.managementData.kpi_kind_select_option = this.kpiFieldOption.find(
          x => x.label == this.managementData.kpi_kind,
        ).value;
      }
    },
    async changeTargetYear(e) {
      this.$store.commit('setSelectYearToKpiTarget', String(e.getFullYear()));
      console.log('[changeTargetYear] this.selectDate : ', this.selectDate);
      await this.FETCH_WITH_PAYLOAD('FETCH_KPI_TARGET', this.selectDate);
    },
    getProductName(id) {
      let hit = this.products.find(x => x.id == id);
      return hit != undefined ? hit.name : '';
    },
    selectProduct(arg) {
      console.log(arg);
      this.managementData.product_id = arg.id;
      this.showProductSearch = false;
    },
    async deleteKpiTarget() {
      this.showSpinner();
      await this.$store
        .dispatch('DELETE_FETCH_KPI_TARGET', this.kpis[this.selectedIndex].id)
        .then(() => {
          this.openOneButtonModal('삭제 성공', '성공적으로 삭제되었습니다.');
          console.log('[delete] this.selectDate : ', this.selectDate);
          this.FETCH_WITH_PAYLOAD_NO_SPINNER(
            'FETCH_KPI_TARGET',
            this.selectDate,
          );
          this.$store.commit('setSelectedIndexToKpiTarget', -1);
          this.$store.commit('setManagementDataToKpiTarget', {
            id: -1,
            kpi_year: this.selectDate,
            kpi_no: -1,
            kpi_kind_select_option: -1,
            kpi_name: '',
            kpi_kind: '',
            unit_cd: null,
            product_id: null,
            crt_val: 0,
            tgt_val: 0,
            wgt_val: 0,
            remark: '',
          });
        })
        .catch(() => {
          this.openOneButtonModal('삭제 중 오류', '삭제 중 오류 발생');
        })
        .finally(() => {
          this.CloseModal();
          this.hideSpinner();
        });
    },

    async submitForm() {
      if (!this.vaildProduct) {
        let payload = this.lodash.clonedeep(this.managementData);
        payload.crt_val = this.$makeNumber(payload.crt_val);
        payload.tgt_val = this.$makeNumber(payload.tgt_val);
        payload.wgt_val = this.$makeNumber(payload.wgt_val);

        this.showSpinner();
        if (this.selectedIndex == -1) {
          this.$store
            .dispatch('INSERT_FETCH_KPI_TARGET', payload)
            .then(() => {
              this.openOneButtonModal(
                '등록 성공',
                '성공적으로 등록하였습니다.',
              );
              console.log('[insert] this.selectDate : ', this.selectDate);
              this.FETCH_WITH_PAYLOAD_NO_SPINNER(
                'FETCH_KPI_TARGET',
                this.selectDate,
              );
              this.SelectRow(-1);
            })
            .catch(() => {
              this.openOneButtonModal('등록 실패', '등록에 실패하였습니다.');
            })
            .finally(() => {
              this.hideSpinner();
            });
        } else {
          console.log(payload, 'ds');
          this.$store
            .dispatch('UPDATE_FETCH_KPI_TARGET', payload)
            .then(() => {
              this.openOneButtonModal(
                '수정 성공',
                '성공적으로 수정하였습니다.',
              );
              console.log('[update] this.selectDate : ', this.selectDate);
              this.FETCH_WITH_PAYLOAD_NO_SPINNER(
                'FETCH_KPI_TARGET',
                this.selectDate,
              );
              this.SelectRow(-1);
            })
            .catch(() => {
              this.openOneButtonModal('수정 실패', '수정에 실패하였습니다.');
            })
            .finally(() => {
              this.hideSpinner();
            });
        }
      } else {
        this.openOneButtonModal('중복 입력', '중복된 분야, 제품이 있습니다.');
      }
    },
    typing(e) {
      this.managementData.remark = e.target.value.trim();
    },
  },
  async created() {
    if (this.products.length < 1) {
      await this.FETCH('FETCH_PRODUCT_WITH_COMPANY', '제품');
    }
    console.log('[created] this.selectDate : ', this.selectDate);
    if (this.selectDate == null) {
      let year = String(new Date().getFullYear());
      this.$store.commit('setSelectYearToKpiTarget', year);
    }
    await this.FETCH_WITH_PAYLOAD_NO_SPINNER(
      'FETCH_KPI_TARGET',
      this.selectDate,
    );
    this.hideSpinner();
  },
};
</script>

<style></style>
